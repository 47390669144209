import React, { Component } from 'react'
import Layout from "../components/layout";
import Breadcrumb from '../components/breadcrumb'
import { Banner } from '../components/banner';
import { Container, Input, Form, ListGroup, ListGroupItemHeading, ListGroupItemText, ListGroupItem } from 'reactstrap'
import { searchData } from '../data/searchData';


export default class search extends Component {
    constructor(props){
        super(props)

        // console.log(this.props)
        const queryData = !this.props.location.state || !this.props.location.state.data ? null : this.props.location.state.data

        // console.log(queryData)
        this.state = {
            query : queryData,
            search : ""
        }
    }

    handleChange = event => {
        this.setState({
            [event.target.name] : event.target.value
        }, () => {
            this.setState({
                query : this.state.search
            })
        })
    }

    handleSubmit = event => {
        event.preventDefault();
    }

    render() {
        return (
        <SearchLayout location={this.props.location} >
            <Form onSubmit={this.handleSubmit}>
                <Input
                    name="search"
                    type="text"
                    value={this.state.search}
                    onChange={this.handleChange}
                    placeholder="Search keyword..."
                />
            </Form>
            <h3>Search Results for : "{this.state.query}"</h3>
            <ListGroup tag="div">
                <SearchContent filter={this.state.query} />
            </ListGroup>
        </SearchLayout>
        )
    }
}

export const SearchContent = props => {
    const search = !props.filter ? '' : props.filter
    let resCnt = 0
    let entryCnt = searchData.length

    return searchData.map(function(data, ind){
        if(data.description.search(new RegExp(search, "i")) !== -1){
            resCnt += 1;
            return (
                <ListGroupItem key={ind} >
                    <ListGroupItemHeading tag="a" href={data.path}>
                        <b>{data.title}</b>
                    </ListGroupItemHeading>
                    <ListGroupItemText>
                        {data.description}
                    </ListGroupItemText>
                </ListGroupItem>
            )
        } else {
            if(ind === entryCnt - 1 && resCnt === 0){
                return 'No results found'
            }
            return ''
        }
    })
}

export const SearchLayout = props => {
    return(
        <Layout>
            <Banner title={`Search`} />
            {props.location ? (<Breadcrumb loc={props.location}/>) : undefined}
            <Container
                style={{
                padding: '2rem 2rem 2.5rem',
                minHeight: '75vh'
                }}
            >
                {props.children}
            </Container>
        </Layout>
    )
}